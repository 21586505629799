import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const BUSINESS_AND_CHAMA_BASE_ENDPOINT = `${REACT_APP_BASE_URL}/v1/investor/business-chama`;

const LOGIN_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/login`;
const LOGIN_WITH_GOOGLE_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/google/login`;
const REGISTER_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/register`;
const SIGNUP_WITH_GOOGLE_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/google/register`;
const REGISTER_BUS_CHAMA = `${BUSINESS_AND_CHAMA_BASE_ENDPOINT}/create`;
const REGISTER_BUS_CHAMA_AFTER_REGISTRATION = `${BUSINESS_AND_CHAMA_BASE_ENDPOINT}/create-after-registration`;
const REQUEST_PASSWORD_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/request-reset-password`;
const REQUEST_PASSWORD_CONFIRMATION_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/password-reset-confirmation`;
const REQUEST_EMAIL_VERIFICATION = `${REACT_APP_BASE_URL}/v1/investor/auth/verify`;
const REQUEST_RESEND_EMAIL_VERIFICATION = `${REACT_APP_BASE_URL}/v1/investor/auth/resend-verification`;
const GENERATE_ACCESS_TOKEN_MAIL = `${REACT_APP_BASE_URL}/v1/investor/auth/generate-2f-token`;
const SEND_ACCESS_TOKEN = `${REACT_APP_BASE_URL}/v1/investor/auth/validate-2f-token`;
const GET_ORIGIN_PARTNER = `${REACT_APP_BASE_URL}/v1/investor/auth/origin-partner/`;
const UPDATE_USER_REGISTERED_TO_COMMUNITY = `${REACT_APP_BASE_URL}/v1/investor/investors`;

const login = async (email, password) => {
  const res = await axios.post(LOGIN_URL, { email, password });
  return res;
};

const loginWithGoogle = async email => {
  const res = await axios.post(LOGIN_WITH_GOOGLE_URL, { email });
  return res;
};

const register = async (payload, campaignTrackingData) => {
  const queries = campaignTrackingData
    ? Object.values(campaignTrackingData).filter(query => !!query)
    : [];

  return axios.post(REGISTER_URL, {
    ...payload,
    ...(queries.length && { campaignTrackingData }),
  });
};

const signupWithGoogle = async payload => {
  const response = await axios.post(SIGNUP_WITH_GOOGLE_URL, payload);
  return response;
};

const registerBusinessChamaAfterRegistration = async details => {
  const response = await axios.post(REGISTER_BUS_CHAMA_AFTER_REGISTRATION, {
    ...details,
  });
  return response;
};

const registerBusinessChama = async details => {
  const response = await axios.post(REGISTER_BUS_CHAMA, { ...details });
  return response;
};

const updateBusinessChama = async (investorId, payload) => {
  const response = await axios.put(
    `${BUSINESS_AND_CHAMA_BASE_ENDPOINT}/${investorId}/account-info`,
    payload,
  );
  return response;
};

const requestPassword = async email => {
  const response = await axios.post(REQUEST_PASSWORD_URL, { email });
  return response;
};

const requestPasswordConfirmation = async (id, password, passwordResetCode) => {
  const response = await axios.put(REQUEST_PASSWORD_CONFIRMATION_URL, {
    id,
    password,
    passwordResetCode,
  });
  return response;
};

const requestEmailVerification = async payload => {
  const response = await axios.post(REQUEST_EMAIL_VERIFICATION, payload);
  return response;
};

const requestResendEmailVerification = async email => {
  const response = await axios.post(REQUEST_RESEND_EMAIL_VERIFICATION, {
    email,
  });
  return response;
};

const generateAccessTokenAndSendMail = async () => {
  const response = await axios.post(GENERATE_ACCESS_TOKEN_MAIL);
  return response;
};

const sendAccessToken = async token => {
  const response = await axios.post(SEND_ACCESS_TOKEN, { token });
  return response;
};

const fetchOriginPartner = async originPartnerId => {
  const response = await axios.get(`${GET_ORIGIN_PARTNER}${originPartnerId}`);
  return response;
};

const updateUserJoinedTheCommunity = () => {
  return axios.put(`${UPDATE_USER_REGISTERED_TO_COMMUNITY}/join-community`);
};

export {
  fetchOriginPartner,
  generateAccessTokenAndSendMail,
  login,
  loginWithGoogle,
  register,
  signupWithGoogle,
  registerBusinessChama,
  registerBusinessChamaAfterRegistration,
  requestEmailVerification,
  requestPassword,
  requestPasswordConfirmation,
  requestResendEmailVerification,
  sendAccessToken,
  updateBusinessChama,
  updateUserJoinedTheCommunity,
};
