import React, { useEffect, useState } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { connect, useDispatch, useSelector } from 'react-redux';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import InvestAndSaveIcon from '../../../icons/investBtnIcon.svg';
import { messages } from '../../../utils/messages';
import SavingAccountMesssage from '../../Plan/components/SavingAccoutMessage';
import SavingPlanCard from '../../Plan/components/SavingPlanCard';

import * as profile from '../../Profile/_redux/profileRedux';
import * as plansDailyTrade from '../_redux/dashboardReducers';
import { actions as kycReminderAction } from '../_redux/dashboardReducers';

import PortfolioIcon from '../dashboard-images/Portfolio.svg';
import { fetchSinglePlanDetails } from '../../Plan/_redux/plansActions';
import { actions as pocketActions } from '../../Pocket/_redux/reducers';

import { actions as currencyActions } from '../../Account/_redux/currencies/currenciesReducers';
import { actions as conversionActions } from '../../Account/_redux/conversions/conversionReducers';

import { fetchUserPockets } from '../../Pocket/_redux/axiosRequest';
import { actions as pocketsActions } from '../../Pocket/_redux/reducers';
import {
  fetchLearningVideos,
  fetchPocketsAndPortfolioValues,
} from '../_redux/dashboardHttpRequests';

import { fetchConversions } from '../../Account/_redux/conversions/conversionActions';
import { fetchCurrencies } from '../../Account/_redux/currencies/currencyActions';

import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';
import PageBottomContent from '../../../helpers/pageBottomContent';
import { fetchHotFunds } from '../../Plan/_redux/plansActions';
import { Promo } from './Promo';
import { LearnCard } from './learnCard';
import { PortfolioValueAndPocket } from '../components/PortfolioValueAndPocket';
import { AddNewCurrency } from '../components/AddNewCurrency';
import { displayInvestorFirstName } from '../../../helpers/displayInvestorFirstName';
import { CustomButton } from '../../Common/ReusableButtons';
import useUserProfile from '../../../helpers/CustomHook/useUserProfile';
import { Titles } from '../../Common/Titles';
import ErrorModal from '../../Common/ErrorModal';
import { handleKycRouting } from '../../../helpers/handleKycRouting';
import kycRejectionModalHeaderIcon from '../dashboard-images/kycRejectionHeaderIcon.svg';
import { eventTracking } from '../../../eventTracking/httpRequest';
import { customerIOEventType } from '../../../helpers/constants';

const Dashboard = ({ plansDailyTradingAction }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState(false);
  const { REACT_APP_ORIGIN_PARTNER_PLAN_ID } = process.env;

  const { investor, user } = useSelector(state => state.profile);
  const { kycLevel, kycStatus, pendingKyc, investorType } = investor;

  const kycChecks = { kycLevel: kycLevel?.level, kycStatus, pendingKyc };

  const displayKycRejectionReason = useSelector(
    state => state.displayKycRejectionReason,
  );

  const { apprPortfolioValue, originPartner } = investor;

  const [pocketAndPortfolioValues, setPocketAndPortfolioValues] = useState();

  const { updateInvestorProfile } = useUserProfile();

  const dispatch = useDispatch();

  const fetchSavingPlan = async planId => {
    try {
      const res = await fetchSinglePlanDetails(planId);
      setPlan(res.data.data);
    } catch (error) {
      // display error
      setLoading(false);
    }
  };

  const [plans, setPlans] = useState(false);
  const [videos, setVideos] = useState([]);

  const fetchVideos = async () => {
    try {
      setLoading(true);
      const res = await fetchLearningVideos(4);
      const data = res.data;
      setVideos(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchPlans = async () => {
    try {
      setLoading(true);

      const res = await fetchHotFunds();
      const plansOnOffer = res.data.data;

      setPlans(plansOnOffer);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const renderSavingsPlan = () => {
    return (
      <div id="dashboard-saving-plan" className="mt-2 mb-4">
        <div className="p-3 saving-plans-container px-5">
          <div className="mr-5" key={plan?.id}>
            <SavingPlanCard
              data={plan}
              key={plan?.id}
              planTypeName={firstLetterUpperCase(plan?.planType?.name)}
              url={`/plans/fixed-return/${plan?.id}`}
            />
          </div>
        </div>

        <SavingAccountMesssage
          message={messages.savingFundAlert}
          classes="mx-7 mb-7"
        />
      </div>
    );
  };

  const fetchPockets = async () => {
    try {
      const response = await fetchUserPockets();
      dispatch(pocketsActions.updatePockets(response?.data?.items));
    } catch (error) {}
  };

  const getPortfolioValues = async () => {
    setLoading(true);
    try {
      const response = await fetchPocketsAndPortfolioValues();
      setPocketAndPortfolioValues(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem('previousPage');
    if (originPartner) {
      fetchSavingPlan(Number(REACT_APP_ORIGIN_PARTNER_PLAN_ID));
    }

    fetchPlans();
    fetchVideos();
    fetchPockets();
    getPortfolioValues();
    dispatch(pocketActions.updatePocket(null));
    localStorage.removeItem('pathName');
  }, [plansDailyTradingAction]);

  useEffect(() => {
    const getData = async () => {
      const {
        data: { data: allCurrencies },
      } = await fetchCurrencies();
      const { data: { data: allConversions } = {} } = await fetchConversions();
      dispatch(currencyActions.currencyAction(allCurrencies));
      dispatch(conversionActions.conversionAction(allConversions));
    };

    getData();
  }, [dispatch]);

  useEffect(() => {
    updateInvestorProfile();
  }, [updateInvestorProfile]);

  useEffect(() => {
    const getData = async () => {
      const {
        data: { data: allCurrencies },
      } = await fetchCurrencies();
      const { data: { data: allConversions } = {} } = await fetchConversions();
      dispatch(currencyActions.currencyAction(allCurrencies));
      dispatch(conversionActions.conversionAction(allConversions));
    };

    getData();
  }, [dispatch]);

  const handleKycRejectionModalButton = () => {
    localStorage.setItem('previousPage', history.location.pathname);
    const link = handleKycRouting(kycChecks, '/manage-account', investorType);

    return link;
  };

  const closeKycRejectionReasonModal = () => {
    dispatch(kycReminderAction.displayKycRejectionReasonModal(false));
  };

  const handleInvestButtonClick = async () => {
    try {
      await eventTracking(customerIOEventType.INVEST_ON_HOME_PAGE);
    } catch (error) {}
  };

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <>
          {/* mobileview */}
          <div className="mobile-hello">
            <div className="welcome-message-hello">
              Hello and Welcome
              <br />
              <span className="orange-text text-13">
                {' '}
                {displayInvestorFirstName(
                  investor?.investorType,
                  user?.firstName,
                  investor?.businessChamaName,
                  20,
                )}
              </span>
            </div>
            <div className="orange-icon">
              <Link to="/plans" onClick={handleInvestButtonClick}>
                <CustomButton
                  buttonText={
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={InvestAndSaveIcon}
                        alt={'Invest and save icon'}
                      />
                      <span className="ml-2 font-weight-500">Invest</span>
                    </div>
                  }
                  loading={loading}
                  buttonWidth={'button-md-w-121'}
                  // handleOnClick={handleInvestButtonClick}
                  classes={'bg-primary-orange orange-button-hover text-white'}
                />
              </Link>
            </div>
          </div>
          {apprPortfolioValue?.profit || apprPortfolioValue?.profit === 0 ? (
            <div className="dashboard-top-header py-3">
              <div className="dashboard-top-header-sections header-mobile-section">
                <div className="profit-details">
                  <div className="d-flex align-items-center">
                    <div className="profit-title mr-5">Profit %</div>
                    <SVG
                      src={toAbsoluteUrl(
                        `/media/svg/ndovu/polygon-${
                          apprPortfolioValue?.profit < 0 ? 'red' : 'green'
                        }.svg`,
                      )}
                    />
                  </div>

                  <div className="profit-figure">{`${apprPortfolioValue?.profit}%`}</div>
                </div>

                <div>
                  <img className="ml-8" src={PortfolioIcon} alt={'k'} />
                </div>
              </div>
              <div className="orange-icon">
                <Link to="/plans" onClick={handleInvestButtonClick}>
                  <CustomButton
                    buttonText={
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={InvestAndSaveIcon}
                          alt={'Invest and save icon'}
                        />
                        <span className="ml-2 font-weight-500">Invest</span>
                      </div>
                    }
                    loading={loading}
                    buttonWidth={'width-140'}
                    classes={'bg-primary-orange orange-button-hover text-white'}
                  />
                </Link>
              </div>
            </div>
          ) : null}

          <>
            {pocketAndPortfolioValues && pocketAndPortfolioValues?.length ? (
              <div className="mt-10">
                <Titles title={'Your Assets'} />
                {/* TODO: <PotentialCurrencies investment={investments} /> */}
                <div
                  className="d-flex w-100 overflow-auto pb-5"
                  style={{ gap: '20px' }}
                >
                  {pocketAndPortfolioValues && pocketAndPortfolioValues?.length
                    ? pocketAndPortfolioValues.map((portfolioValue, index) => (
                        <PortfolioValueAndPocket
                          currency={portfolioValue?.currency}
                          portfolioValue={portfolioValue?.portfolioValue}
                          pocketBalance={portfolioValue?.pocketBalance}
                          pocketId={portfolioValue?.pocketId}
                          flag={portfolioValue?.currencyFlag}
                          key={index}
                        />
                      ))
                    : null}
                  {/* 🔴 DO NOT REMOVE THIS AddNewCurrency COMPONENT. IT WILL BE NEEDED IN FUTURE 🔴 */}

                  {/* <AddNewCurrency /> */}
                </div>
              </div>
            ) : null}

            {/* 
              IMPORTANT: For kyosk users, they only need to see the profit and portfolio value card only. 
              Thus, any new items in the dashboard should be exempted for these users. To determine this users, we check the boolean field "originPartner".
            */}

            {originPartner && plan ? (
              renderSavingsPlan()
            ) : (
              <>
                <Promo plans={plans} />
                <div className="d-flex flex-column mt-10">
                  <div className="d-flex justify-content-between font-weight-500 mb-5">
                    <Titles title={'Learn how to grow your wealth'} />
                    {videos?.meta?.totalItems > videos?.items?.length ? (
                      <a
                        href="https://www.ndovu.co/ndovu-videos"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue"
                      >
                        <Titles title="See More" />
                      </a>
                    ) : null}
                  </div>

                  <div className="flex-row d-flex overflow-auto learn">
                    <LearnCard videos={videos} />
                  </div>
                </div>
              </>
            )}

            <PageBottomContent />

            <ErrorModal
              open={displayKycRejectionReason}
              setOpen={() => closeKycRejectionReasonModal()}
              content={
                <span>
                  The documents you provide do not meet the required criteria
                  because{' '}
                  <span className="font-weight-500">
                    {investor?.kycRejectionReason?.description}
                  </span>
                </span>
              }
              moreClasses="bg-orange-100"
              generateModalButtonRedirectLink={handleKycRejectionModalButton}
              buttonText="Update"
              headerIcon={kycRejectionModalHeaderIcon}
              modalTitle={'KYC Rejected'}
            />
          </>
        </>
      )}
    </>
  );
};

export default connect(null, {
  ...profile.actions,
  ...plansDailyTrade.actions,
})(Dashboard);
