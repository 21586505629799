import React from 'react';
import { useHistory } from 'react-router-dom';
import { Titles } from '../../Common/Titles';
import { motion } from 'framer-motion/dist/framer-motion';

const Promo = ({ plans }) => {
  const history = useHistory();

  return (
    <motion.div
      className={plans?.length > 0 ? 'mt-4' : 'd-none'}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.6,
        delay: 0.8,
        scale: {
          type: 'spring',
          damping: 15,
          stiffness: 50,
          restDelta: 0.001,
        },
      }}
    >
      <Titles title={'Hot Funds'} />
      <div className="fund-plans">
        {plans?.length > 0 &&
          plans.map(plan => {
            return (
              <div
                onClick={() => history.push(`/plans/${plan?.id}`)}
                key={plan?.id}
              >
                <img
                  src={
                    plan.imageUrl
                      ? plan.imageUrl
                      : 'http://localhost:7007/uploads/assets/fund/small/Vanguard_Information_Tech_ETF_(VGT).png'
                  }
                  alt="PromoImage"
                  className="promo-card"
                  key={plan.id}
                />
              </div>
            );
          })}
      </div>
    </motion.div>
  );
};

export { Promo };
